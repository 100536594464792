import ApiService from "./ApiService";

class BatchService extends ApiService {
  /**
   * Get all batches with optional query parameters
   * @param {string} query - Optional query parameters
   * @returns {Promise<Batch>}
   */
  getAll(query) {
    return this.instance.get(`/v1/batches?${query}`);
  }

  /**
   * Get a batch by their ID
   * @param {ObjectId} id - The ID of the batch to get
   * @returns {Promise<Batch>}
   */
  get(id) {
    return this.instance.get(`/v1/batches/${id}`);
  }

  /**
   * Update the current batch with the given data
   * @param {object} data - The data to update the batch with
   * @returns {Promise<Batch>}
   */
  create(data) {
    return this.instance.post("/v1/batches", data);
  }

  /**
   * Update a batch with the given ID with the given data
   * @param {object} data - The data to update the batch with
   * @param {ObjectId} id - The ID of the batch to update
   * @returns {Promise<Batch>}
   */
  updateById(id, data) {
    return this.instance.patch(`/v1/batches/${id}`, data);
  }
}

const batchServiceInstance = new BatchService();

export default batchServiceInstance;
