import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "@core/components/ui/DataTable";
import { getTags } from "store/tag";

const AllTags = ({ batchId }) => {
  const dispatch = useDispatch();
  const [query, setQuery] = useState("");
  const data = useSelector((state) => state.tag);

  useEffect(() => {
    dispatch(getTags(`batch=${batchId}&${query}`));
  }, [dispatch, batchId, query]);

  const columns = [
    {
      name: "url",
      label: "URL",
    },
    {
      name: "profile",
      label: "Profile",
      options: {
        download: false,
      },
    },
  ];

  return (
    <DataTable
      title={"Tags"}
      data={data}
      columns={columns}
      setQuery={setQuery}
      download={true}
    />
  );
};

export default AllTags;
