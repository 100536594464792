import ApiService from "./ApiService";

class TagService extends ApiService {
  /**
   * Get all tags with optional query parameters
   * @param {string} query - Optional query parameters
   * @returns {Promise<Tag>}
   */
  getAll(query) {
    console.log(query);
    return this.instance.get(`/v1/tags?${query}`);
  }
}

const tagServiceInstance = new TagService();

export default tagServiceInstance;
